<template>
    <div id="user">
        <transition name="slide-fade" mode="out-in">
        <div class="mcontainer" v-if="this.$route.name === 'AllUser'">
            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">All User</h2>
                </div>
                <div class="search">
                    <input type="text" class="form-control mb-0" v-model="search" v-on:keyup.enter="searchChange()" placeholder="Search ...">
                </div>
            </div>
            <div class="relative">
                <div class="px-3 py-3 card">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Fullname</th>
                                <th>Email</th>
                                <th>Type</th>
                                <th>Faculity</th>
                                <th>No Induk</th>
                                <th>Status</th>
                                <th style="width: 150px;">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in all_user" :key="data.id">
                                <td>{{ data.fullname }}</td>
                                <td>{{ data.email }}</td>
                                <td>{{ data.type }}</td>
                                <td>{{ data.faculity }}</td>
                                <td>{{ data.no_induk }}</td>
                                <td>
                                    <span v-if="data.is_banned == 0">Active</span>
                                    <span v-if="data.is_banned == 1">Banned</span>
                                </td>
                                <td>
                                    <button v-if="data.is_banned == 0" class="btn btn-danger px-3" @click="banned(data.id, 'Banned')" title="Banned"><Icon icon='ci:off-outline-close' /></button>
                                    <button v-if="data.is_banned == 1" class="btn btn-warning px-3" @click="banned(data.id, 'Unbanned')" title="Unbanned"><Icon icon='icon-park-outline:refresh-one' /></button>
                                    <router-link :to="'/app/all_user/update/' + data.username" class="btn btn-primary px-3 ml-2"><Icon icon="akar-icons:edit" /></router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <center>
                        <b-spinner v-if="loading" label="Loading..." small />
                    </center>
                    <div class="flex justify-center mt-6" v-if="loadmore_show">
                        <a href="javascript:void(0)" @click="loadmore()" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                            Load more
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <router-view/>
        </transition>
    </div>
</template>
<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'AllUser',
        components: {
            Icon
        },
        data () {
            return {
                all_user: [],
                search: '',
                totalPage: 1,
                page: 1,
                loadmore_show: true,
                loading: false
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            async fetchData () {
                if (this.$route.name === 'AllUser') {
                    this.loading = true
                    try {
                        const res = await axios.get('user/all?search=' + this.search + '&page=' + this.page)
                        this.loading = false
                        this.page = res.data.current_page
                        this.totalPage = res.data.last_page
                        if (this.page === res.data.last_page) {
                            this.loadmore_show = false
                        }
                        this.all_user.push(...res.data.data)
                    } catch (e) {
                        console.error(e)
                    }
                }
            },
            loadmore () {
                this.page = this.page + 1
                this.fetchData()
            },
            searchChange () {
                this.page = 1
                this.all_user = []
                this.fetchData()
            },
            banned (id, type) {
                const parents = this
                this.$swal({
                    title: 'Are you sure?',
                    text: '',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, ' + type + ' it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.get('user/banned/' + id).then(function (response) {
                            if (response.data.status) {
                                parents.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message
                                }).then(() => {
                                    parents.searchChange()
                                })
                            } else {
                                parents.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Something Wrong'
                                })
                            }
                        }).catch(function () {
                            parents.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something Wrong'
                            })
                        })
                    }
                })
            }
        }
    }
</script>
